import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { IUserProfile } from "../../company/users/shared/user-models";
import { IncidentService } from "src/app/services/api/incident/incident.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { MenuItem } from "primeng/api/menuitem";
import { Table } from "primeng/table";
import { LazyLoadEvent, MessageService } from "primeng/api";
import {
  SetSelectedColumns,
  TableReset,
  IsPaginatorState,
} from "src/app/helpers/table.helper";
import { ExportTable } from "../../products-and-services/science-logic/cmdb-devices/shared/export-devices";
import { ToastService } from "src/app/services/toast.service";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { cloneDeep } from "lodash-es";
import { Filters, TableColumn, TableColumnColor } from "src/app/models/table";
import {
  ExportFileName,
  ExportService,
  ExportTableEndpoint,
  ExportType,
} from "src/app/services/export.service";
import { IDownloadFile, ITableToFile } from "src/app/models/export-models";
import { DateTime } from "luxon";
import { Paginator, PaginatorState } from "primeng/paginator";
import { IncidentsColumns } from "./incidents.columns";
import { GetMultipleIncidentsResponse } from "src/app/models/incident/incident.models";

@Component({
  selector: "incidents",
  templateUrl: "incidents.component.html",
  styleUrls: ["incidents.component.scss"],
  providers: [MessageService],
})
export class IncidentsComponent implements OnInit {
  @ViewChild("dataTable", { static: true }) dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  stateOptions: any[] = [
    { label: "Company", value: true },
    { label: "Mine", value: false },
  ];
  incidentsSubject = new BehaviorSubject<Array<GetMultipleIncidentsResponse>>(
    []
  );
  incidents$ = this.incidentsSubject.asObservable();
  user: IUserProfile;
  hasCompanyToggle: boolean;
  isImpersonating: boolean = false;
  totalRecords: number = 0;
  cols: TableColumn[] = cloneDeep(IncidentsColumns);

  companyToggle: boolean = true;

  _selectedColumns: TableColumn[] = [];

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () =>
        ExportTable(
          this.dataTable,
          this.incidentsSubject.value,
          this._selectedColumns,
          "all"
        ),
    },
  ];

  currentTableFilters = {};

  permissions = ["TICKETS_COMPANY_READ", "TICKETS_INCIDENTS_UPDATE"];
  firstLoad = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private incidentService: IncidentService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService,
    private toastService: ToastService,
    private exportService: ExportService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUser();
    const breadcrumbs: MenuItem[] = [
      { label: "Incidents", routerLink: "/secure/tickets/incidents" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    const url = window.location.href;
    // TODO review with new columns structure
    this.hasCompanyToggle = this.user.uTimicoPortalPermissions.includes(
      "TICKETS_COMPANY_READ"
    );
    this.isImpersonating = this.authService.isImpersonating();
  }

  loadTableFromURL(): Filters {
    const filters: Filters = {};
    const params = this.route.snapshot.params;
    if (params["OpenedAt.StartDate"] && params["OpenedAt.EndDate"]) {
      const startDateDateTime = DateTime.fromFormat(
        params["OpenedAt.StartDate"],
        "dd/MM/yyyy"
      );
      // Convert to a JavaScript Date object
      const startDate = startDateDateTime.toJSDate();
      const endDateDateTime = DateTime.fromFormat(
        params["OpenedAt.EndDate"],
        "dd/MM/yyyy"
      );
      // Convert to a JavaScript Date object
      const endDate = endDateDateTime.toJSDate();
      const values = [startDate, endDate];
      let column = this.cols.find(
        (currentFilter) => currentFilter.serviceNowField === "SysCreatedOn"
      );
      if (column) {
        filters[column.field] = {
          value: values,
          matchMode: "between",
        };
      }
    }
    if (params["State"]) {
      let column = this.cols.find(
        (currentFilter) => currentFilter.serviceNowField === "State"
      );
      if (column) {
        filters[column.field] = {
          value: params["State"],
          matchMode: "in",
        };
      }
    }
    if (params["Priority"]) {
      let column = this.cols.find(
        (currentFilter) => currentFilter.serviceNowField === "Priority"
      );
      if (column) {
        filters[column.field] = {
          value: params["Priority"],
          matchMode: "in",
        };
      }
    }
    return filters;
  }

  async loadMultipleIncidents(
    table: Table,
    event?: LazyLoadEvent | PaginatorState
  ): Promise<void> {
    if (IsPaginatorState(event)) {
      table.rows = event?.rows;
      table.first = event?.first;
    }

    const isFirstLoad = this.firstLoad ? true : false;
    table.loading = true;
    // Reset items to empty array to ensure loader shows
    this.incidentsSubject.next([]);

    if (isFirstLoad) {
      const additionalFilters = this.loadTableFromURL();
      this.reset(table, true, cloneDeep(IncidentsColumns), additionalFilters);
      this.firstLoad = false;
    }

    const currentPage =
      event?.first != null && event?.rows != null
        ? event.first / event.rows
        : table.first / table.rows;
    const currentPerPage = event?.rows ? event.rows : table.rows;

    let serviceNowFilter = BuildServiceNowQuery(
      table,
      this.cols,
      currentPerPage,
      currentPage,
      event
    );

    if (!this.companyToggle) {
      serviceNowFilter.Filter.push({
        Column: "UCallerSysId",
        Value: [this.user?.sysId],
      });
    }

    const incidentCount =
      (await firstValueFrom(this.incidentService.getCount(serviceNowFilter))) ??
      0;

    const incidents = await firstValueFrom(
      this.incidentService.getMultiple(serviceNowFilter)
    );
    this.totalRecords = incidentCount;
    this.incidentsSubject.next(incidents);
    table.loading = false;
  }

  async onToggleChange(table: Table): Promise<void> {
    await this.loadMultipleIncidents(table);
  }

  getColor(colors: TableColumnColor[], value: string) {
    return colors[value] ?? null;
  }

  handleRowClicked(event) {
    this.router.navigateByUrl(`secure/tickets/incident/${event.sysId}`);
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  defaultExport() {
    ExportTable(this.dataTable, this.incidentsSubject.value, this.cols, "all");
  }

  async exportAll() {
    this.toastService.showToastInfo(
      "Downloading - ",
      " We are just sorting your data out for you. This won't take long."
    );

    const serviceNowFilter = BuildServiceNowQuery(this.dataTable, this.cols);
    if (!this.companyToggle) {
      serviceNowFilter.Filter.push({
        Column: "UCallerSysId",
        Value: [this.user?.sysId],
      });
    }

    const fields = this._selectedColumns
      .filter((item) => item.exportField)
      .map((item) => item.exportField);
    const endpoint: string = ExportTableEndpoint.Incidents;
    const body: ITableToFile = {
      Filter: serviceNowFilter.Filter,
      ExportType: ExportType.Csv,
      CurrentPage: 0,
      Amount: 25,
      Pagination: false,
      ExportColumns: fields,
      UserEmail: this.user.email,
    };
    const downloadedFile: IDownloadFile =
      await this.exportService.exportTableToFile(endpoint, body);
    this.exportService.downloadFile(
      downloadedFile,
      ExportType.Csv,
      ExportFileName.incidents
    );
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[] = IncidentsColumns,
    additionalFilters?: Filters
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
      additionalFilters: additionalFilters,
    });
  }
}
