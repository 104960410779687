import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ApiRequest,
  IRequestOptions,
} from "src/app/helpers/api.request.helper";
import {
  CommentsResponse,
  CreateCommentRequest,
} from "src/app/models/comments/comment.models";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";

const taskDirectory = "ServiceRequest";

@Injectable({
  providedIn: "root",
})
export class ServiceRequestService {
  constructor(private apiRequest: ApiRequest) {}

  create(request: any) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/CreateServiceRequest`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getCount(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetServiceRequestCount`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  getSingle(requestId: string) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetSingleServiceRequest`,
      body: requestId,
    };

    return this.apiRequest.request(requestProps);
  }

  getMultiple(request: ServiceNowFilterQuery) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetMultipleServiceRequests`,
      body: request,
    };

    return this.apiRequest.request(requestProps);
  }

  createComment(request: CreateCommentRequest) {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/CreateServiceRequestComment`,
      body: request,
    };
    return this.apiRequest.request(requestProps);
  }

  getComments(incidentSysId: string): Observable<CommentsResponse> {
    const requestProps: IRequestOptions = {
      endpoint: `${taskDirectory}/GetServiceRequestComments`,
      body: incidentSysId,
    };
    return this.apiRequest.request(requestProps);
  }
}
