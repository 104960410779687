import { ChangeRequestTableColumnName } from "src/app/models/change/change.models";
import { TableColumn } from "src/app/models/table";

export enum exportFields {
  number = "number",
  state = "state",
  requestedFor = "requestedFor",
  shortDescription = "subject",
  startDate = "startDate",
  endDate = "endDate",
  closedStatus = "changeOutcome",
}

export const changeColumns: TableColumn[] = [
  {
    field: "number",
    header: "Number",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: true,
    serviceNowField: ChangeRequestTableColumnName.number,
    exportField: exportFields.number,
  },
  {
    field: "shortDescription",
    header: "Subject",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.shortDescription,
    exportField: exportFields.shortDescription,
  },
  {
    field: "uRequestedFor",
    header: "Request For",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.requestedFor,
    exportField: exportFields.requestedFor,
  },
  {
    field: "startDate",
    header: "Planned Start Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.startDate,
    exportField: exportFields.startDate,
  },
  {
    field: "endDate",
    header: "Planned End Date",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.endDate,
    exportField: exportFields.endDate,
  },
  {
    field: "state",
    header: "State",
    display: {
      type: "chip",
      colors: {
        Implementation: "implementation",
        Approval: "approval",
        "Awaiting info": "awitinginfo",
        Closed: "closed",
        "On Hold ": "onhold",
        Pir: "pir",
      },
    },
    filter: {
      type: "multiSelect",
      values: [
        { label: "Approval", value: "Approval" },
        { label: "Implementation", value: "Implementation" },
        { label: "On Hold", value: "On Hold" },
        { label: "Pir", value: "PIR" },
        { label: "Closed", value: "Closed" },
      ],
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.state,
    exportField: exportFields.state,
  },
  {
    field: "uClosedStatus",
    header: "Change Outcome",
    display: { type: "text-highlight" },
    filter: {
      type: "multiSelect",
      values: [
        { label: "Successfully Completed", value: "successfully_completed" },
        { label: "Completed Successful", value: "completed_successful" },
        { label: "Completed Unsuccessful", value: "completed_unsuccessful" },
        { label: "Cancelled", value: "cancelled" },
        { label: "Backed Out", value: "backed_out" },
      ],
    },
    showSortIcon: true,
    default: true,
    serviceNowField: ChangeRequestTableColumnName.closedStatus,
    exportField: exportFields.closedStatus,
  },
];
