import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Message } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { CommentResponse } from "src/app/models/comments/comment.models";

@Component({
  selector: "app-provide-incident-information",
  templateUrl: "./provide-incident-information.component.html",
  styleUrls: ["./provide-incident-information.component.scss"],
})
export class ProvideIncidentInformationComponent implements OnInit {
  infoForm = new FormGroup({
    infoComment: new FormControl("", Validators.required),
  });
  latestComment: CommentResponse = null;
  ticketState: string;
  messageDetail: Message[] | undefined;
  showComment: boolean = false;
  showInput: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private router: Router
  ) {
    this.latestComment = this.dialogConfig.data?.comment;
    this.ticketState = this.dialogConfig.data?.ticketState;
  }

  ngOnInit() {
    switch (this.ticketState) {
      case "Closed":
        this.messageDetail = [
          {
            severity: "error",
            detail:
              "Please note that this ticket has already been closed.\nYou can raise a new incident if you believe that the issue has not been solved.",
          },
        ];
        break;
      default:
        this.showComment = true;
        this.showInput = true;
    }
  }

  close(): void {
    this.ref.close();
  }

  cancel(): void {
    this.ref.close({
      action: "cancel",
    });
  }

  redirect(): void {
    this.ref.close({
      action: "redirect",
    });
  }

  async submitInformation(): Promise<void> {
    this.ref.close({
      action: "comment",
      value: this.infoForm.value?.infoComment,
    });
  }
}
