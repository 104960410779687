import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ReleaseNotesNotificationComponent } from "../../misc/pop-up/release-notes-notification/release-notes-notification.component";
import { CookieService as NgxCookieService } from "ngx-cookie-service";

import { AccountService } from "../account/shared/services/account.service";
import { ICookieSettings } from "../account/shared/models/account-models";
import { ReleaseNotesService } from "src/app/services/api/release-notes/release-notes.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { firstValueFrom } from "rxjs";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { DateTime } from "luxon";
import { OrderService } from "src/app/services/api/order/order.service";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { IUserProfile } from "../company/users/shared/user-models";
import { ServiceNowFilterQueryGeneric } from "src/app/models/servicenow.model";
import { RequestedItemsService } from "src/app/services/api/requested-items/requested-items.service";
import { CaseService } from "src/app/services/api/case/case.service";
import { IncidentService } from "src/app/services/api/incident/incident.service";
import { DashBoardStatistic, dashboardStatistics } from "./dashboard.model";
import { DashboardService } from "src/app/services/api/dashboard/dashboard.service";
import { OutageService } from "src/app/services/api/outage/outage.service";
import { IncidentTableColumnNames } from "src/app/models/incident/incident.models";
import {
  ServiceRequestOpenStateValues,
  ServiceRequestTableColumnName,
} from "src/app/models/service-request/service-request.models";
import {
  OrderOpenStateValues,
  OrderTableColumnNames,
} from "src/app/models/order/order.model";
import { MenuItem } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { ServiceRequestService } from "src/app/services/api/service-request/service-request.service";
import { OrderLineItemService } from "src/app/services/api/order-line-item/order-line-item.service";

@Component({
  selector: "dashboard",
  templateUrl: "dashboard.component.html",
  styleUrls: ["dashboard.component.scss"],
  providers: [DialogService],
})
export class DashboardComponent implements OnInit {
  user: IUserProfile;

  cookieSettings: ICookieSettings;
  additionalCookiesAccepted: boolean = false;
  showCookieBanner: boolean = false;
  additionalCookies: boolean = true;

  graphs: any[] = [];
  releaseNotes: any[] = [];
  ref: DynamicDialogRef | undefined;

  statistics = dashboardStatistics;

  constructor(
    private router: Router,
    private ngxCookieService: NgxCookieService,
    private accountService: AccountService,
    private releaseNotesService: ReleaseNotesService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    public dialogService: DialogService,
    private orderService: OrderService,
    private serviceRequestService: ServiceRequestService,
    private orderLineItemService: OrderLineItemService,
    private caseService: CaseService,
    private incidentService: IncidentService,
    private outageService: OutageService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    const breadcrumbs: MenuItem[] = [
      {
        icon: "pi pi-home",
        command: () => window.location.reload(),
      },
      { label: "Dashboard" },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.user = await this.authService.getUserOrImpersonatedUser();
    // Top Statistics
    this.getIncidentsAllOpenStatistic();
    this.getOrdersAllOpenStatistic();
    this.getServiceRequestsAllOpenStatistic();
    this.getServiceNoticesAllOpenStatistic();
    // Left Month Statistics
    this.getIncidentsOpenedThisMonthStatistic();
    this.getIncidentsOpenedLastMonthStatistic();
    this.getServiceRequestsOpenedThisMonthStatistic();
    this.getServiceRequestsOpenedLastMonthStatistic();
    // Graphs
    this.getGraphs();
    // Cookie Banner
    this.checkShowCookieBanner();
    this.loadCookieSettings();
    // Release Notes
    this.loadReleaseNotes();
  }

  async getIncidentsAllOpenStatistic() {
    const incidentsFilters = [
      {
        Column: IncidentTableColumnNames.state,
        Value: ["1", "2", "3"],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")) {
      incidentsFilters.push({
        Column: IncidentTableColumnNames.raisedBy,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.incidents.allOpen,
      [this.incidentService.getCount.bind(this.incidentService)],
      incidentsFilters
    );
  }

  async getIncidentsOpenedThisMonthStatistic() {
    const incidentsFilters = [
      {
        Column: IncidentTableColumnNames.state,
        // TODO move to variable
        Value: ["1", "2", "3"],
      },
      {
        Column: IncidentTableColumnNames.createdOn,
        Value: [
          `between${DateTime.now().startOf("month").toFormat("dd/MM/yyyy")}`,
          DateTime.now().endOf("month").toFormat("dd/MM/yyyy"),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")) {
      incidentsFilters.push({
        Column: IncidentTableColumnNames.raisedBy,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.incidents.openedThisMonth,
      [this.incidentService.getCount.bind(this.incidentService)],
      incidentsFilters
    );
    this.statistics.incidents.openedThisMonth.command = () => {
      this.router.navigate([
        `/secure/tickets/incidents`,
        {
          "OpenedAt.StartDate": DateTime.now()
            .startOf("month")
            .toFormat("dd/MM/yyyy"),
          "OpenedAt.EndDate": DateTime.now()
            .endOf("month")
            .toFormat("dd/MM/yyyy"),
        },
      ]);
    };
  }

  async getIncidentsOpenedLastMonthStatistic() {
    const incidentsFilters = [
      {
        Column: IncidentTableColumnNames.state,
        // TODO move to variable
        Value: ["1", "2", "3"],
      },
      {
        Column: IncidentTableColumnNames.createdOn,
        Value: [
          `between${DateTime.now()
            .minus({ month: 1 })
            .startOf("month")
            .toFormat("dd/MM/yyyy")}`,
          DateTime.now()
            .minus({ month: 1 })
            .endOf("month")
            .toFormat("dd/MM/yyyy"),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")) {
      incidentsFilters.push({
        Column: IncidentTableColumnNames.raisedBy,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.incidents.openedLastMonth,
      [this.incidentService.getCount.bind(this.incidentService)],
      incidentsFilters
    );
    this.statistics.incidents.openedLastMonth.command = () => {
      this.router.navigate([
        `/secure/tickets/incidents`,
        {
          "OpenedAt.StartDate": DateTime.now()
            .minus({ month: 1 })
            .startOf("month")
            .toFormat("dd/MM/yyyy"),
          "OpenedAt.EndDate": DateTime.now()
            .minus({ month: 1 })
            .endOf("month")
            .toFormat("dd/MM/yyyy"),
        },
      ]);
    };
  }

  async getServiceRequestsOpenedThisMonthStatistic() {
    const serviceRequestFilters = [
      {
        Column: ServiceRequestTableColumnName.state,
        Value: ServiceRequestOpenStateValues,
      },
      {
        Column: ServiceRequestTableColumnName.openedAt,
        Value: [
          `between${DateTime.now().startOf("month").toFormat("dd/MM/yyyy")}`,
          DateTime.now().endOf("month").toFormat("dd/MM/yyyy"),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")) {
      serviceRequestFilters.push({
        Column: ServiceRequestTableColumnName.requestedFor,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.serviceRequests.openedThisMonth,
      [
        this.serviceRequestService.getCount.bind(this.serviceRequestService),
        this.caseService.getCount.bind(this.caseService),
      ],
      serviceRequestFilters
    );
    this.statistics.serviceRequests.openedThisMonth.command = () => {
      this.router.navigate([
        `/secure/tickets/service-requests`,
        {
          "OpenedAt.StartDate": DateTime.now()
            .startOf("month")
            .toFormat("dd/MM/yyyy"),
          "OpenedAt.EndDate": DateTime.now()
            .endOf("month")
            .toFormat("dd/MM/yyyy"),
        },
      ]);
    };
  }

  async getServiceRequestsOpenedLastMonthStatistic() {
    const serviceRequestFilters = [
      {
        Column: ServiceRequestTableColumnName.state,
        Value: ServiceRequestOpenStateValues,
      },
      {
        Column: ServiceRequestTableColumnName.openedAt,
        Value: [
          `between${DateTime.now()
            .minus({ month: 1 })
            .startOf("month")
            .toFormat("dd/MM/yyyy")}`,
          DateTime.now()
            .minus({ month: 1 })
            .endOf("month")
            .toFormat("dd/MM/yyyy"),
        ],
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")) {
      serviceRequestFilters.push({
        Column: ServiceRequestTableColumnName.requestedFor,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.serviceRequests.openedLastMonth,
      [
        this.serviceRequestService.getCount.bind(this.serviceRequestService),
        this.caseService.getCount.bind(this.caseService),
      ],
      serviceRequestFilters
    );
    this.statistics.serviceRequests.openedLastMonth.command = () => {
      this.router.navigate([
        `/secure/tickets/service-requests`,
        {
          "OpenedAt.StartDate": DateTime.now()
            .minus({ month: 1 })
            .startOf("month")
            .toFormat("dd/MM/yyyy"),
          "OpenedAt.EndDate": DateTime.now()
            .minus({ month: 1 })
            .endOf("month")
            .toFormat("dd/MM/yyyy"),
        },
      ]);
    };
  }

  async getOrdersAllOpenStatistic() {
    const orderFilters = [
      {
        Column: OrderTableColumnNames.state,
        Value: OrderOpenStateValues,
      },
    ];
    if (!this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")) {
      orderFilters.push({
        Column: OrderTableColumnNames.requestedFor,
        Value: [this.user?.sysId],
      });
    }
    this.getStatistic(
      this.statistics.orders.allOpen,
      [
        this.orderService.getCount.bind(this.orderService),
        this.orderLineItemService.getCount.bind(this.orderService),
      ],
      orderFilters
    );
  }

  async getServiceRequestsAllOpenStatistic() {
    const requestedItemFilters = [
      {
        Column: ServiceRequestTableColumnName.state,
        Value: ServiceRequestOpenStateValues,
      },
    ];

    if (!this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_READ")) {
      requestedItemFilters.push({
        Column: ServiceRequestTableColumnName.requestedFor,
        Value: [this.user?.sysId],
      });
    }

    this.getStatistic(
      this.statistics.serviceRequests.allOpen,
      [
        this.serviceRequestService.getCount.bind(this.serviceRequestService),
        this.caseService.getCount.bind(this.caseService),
      ],
      requestedItemFilters
    );
  }

  async getServiceNoticesAllOpenStatistic() {
    this.getStatistic(this.statistics.serviceNotices.allOpen, [
      this.outageService.getCount.bind(this.outageService),
    ]);
  }

  async getGraphs() {
    let graphs = await firstValueFrom(this.dashboardService.getGraphs(""));
    graphs.forEach(async (graph) => {
      var graphContainer = { data: null };
      this.graphs.push(graphContainer);
      let graphData = await firstValueFrom(
        this.dashboardService.graphData(graph)
      );
      graphContainer.data = graphData;
    });
  }

  async getStatistic(
    statistic: DashBoardStatistic,
    apiCalls: Function[],
    filters: ServiceNowFilterQueryGeneric[] = []
  ): Promise<void> {
    // Set loading to true
    statistic.loading = true;

    // Build the ServiceNow query and add the filters
    const serviceNowFilterQuery = BuildServiceNowQuery();
    serviceNowFilterQuery.Filter = [
      ...serviceNowFilterQuery.Filter,
      ...filters,
    ];

    try {
      // Ensure that the results are valid numbers and sum them up
      statistic.value = (
        await Promise.all(
          apiCalls.map((fn) => firstValueFrom(fn(serviceNowFilterQuery)))
        )
      )
        .map((value) => (typeof value === "number" && value > 0 ? value : 0))
        .reduce((total, value) => total + value, 0);
    } catch (error) {
      console.error("Error while fetching statistics", error);
      statistic.value = 0;
    } finally {
      statistic.loading = false;
    }
  }

  async loadCookieSettings() {
    this.cookieSettings = await this.accountService.loadCookieSettings();
    this.additionalCookiesAccepted =
      this.cookieSettings?.uCookiesType === "Additional Cookies";
    if (this.cookieSettings === null) {
      this.checkShowCookieBanner();
    }
  }

  async loadReleaseNotes() {
    this.releaseNotes = await firstValueFrom(
      this.releaseNotesService.getReleaseNote()
    );
    // TODO do we still need this?
    if (
      this.releaseNotes[0].sysId !=
      (await this.authService.getUser()).uReleaseNoteViewed
    ) {
      this.ref = this.dialogService.open(ReleaseNotesNotificationComponent, {
        contentStyle: { overflow: "auto" },
        showHeader: false,
        width: "80%",
        data: {
          releaseNote: this.releaseNotes[0],
        },
      });

      firstValueFrom(
        this.releaseNotesService.releaseNoteViewed(this.releaseNotes[0].sysId)
      );
      this.ref.onClose.subscribe((result) => {
        this.authService.refreshUser();
        if (result) {
          this.router.navigateByUrl("secure/release-notes");
        }
      });
    }
  }

  acceptCookies() {
    this.showCookieBanner = false;
    this.ngxCookieService.set(
      "Accept-DigitalSpace-Necessary-Cookies",
      "true",
      10000
    );
    this.ngxCookieService.set(
      "Accept-DigitalSpace-Additional-Cookies",
      this.additionalCookies.toString(),
      10000
    );
    this.toggleAdditionalCookies(this.additionalCookies);
  }

  async toggleAdditionalCookies(toggleSelection: boolean) {
    await this.updateCookieSettings(toggleSelection);
  }

  async updateCookieSettings(cookieSetting: boolean) {
    setTimeout(() => {
      let cookies = {
        SysId:
          this.cookieSettings && this.cookieSettings.uCookiesType != null
            ? this.cookieSettings.sysId
            : "",
        UCookiesType: cookieSetting ? "Additional Cookies" : "",
      };
      this.accountService.updateCookieSettings(cookies);
      if (
        this.ngxCookieService.check("Accept-DigitalSpace-Additional-Cookies")
      ) {
        this.ngxCookieService.set(
          "Accept-DigitalSpace-Additional-Cookies",
          cookieSetting.toString(),
          10000
        );
      }
    }, 500);
  }

  checkShowCookieBanner(): void {
    var storedCookie = this.ngxCookieService.get(
      "Accept-DigitalSpace-Necessary-Cookies"
    );
    if (storedCookie == null || storedCookie == "" || storedCookie == "false") {
      setTimeout(() => {
        this.showCookieBanner = true;
      }, 2000);
    }
  }
}
