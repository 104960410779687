import { IncidentTableColumnNames } from "src/app/models/incident/incident.models";
import { TableColumn, TableSort } from "src/app/models/table";

enum exportField {
  number = "number",
  subject = "subject",
  raisedBy = "raisedBy",
  createdOn = "createdOn",
  priority = "priority",
  state = "state",
  Service = "service",
  symptom = "symptom",
  Location = "location",
  onHoldReason = "onHoldReason",
  cmbdCi = "configurationItem",
}

export const IncidentsColumns: TableColumn[] = [
  {
    field: "number",
    header: "Number",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    disabled: true,
    sortColumnOrder: 1,
    sortOrder: TableSort.desc,
    serviceNowField: IncidentTableColumnNames.number,
    exportField: exportField.number,
  },
  {
    field: "shortDescription",
    header: "Subject",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: IncidentTableColumnNames.shortDescription,
    exportField: exportField.subject,
  },
  {
    field: "callerId",
    header: "Request For",
    display: { type: "text" },
    showSortIcon: true,
    default: true,
    serviceNowField: IncidentTableColumnNames.raisedBy,
    exportField: exportField.raisedBy,
  },
  {
    field: "sysCreatedOn",
    header: "Opened At",
    display: { type: "date" },
    filter: {
      type: "dateRange",
    },
    showSortIcon: true,
    default: true,
    serviceNowField: IncidentTableColumnNames.createdOn,
    exportField: exportField.createdOn,
  },
  {
    field: "priority",
    header: "Priority",
    display: { type: "text" },
    filter: {
      type: "multiSelect",
      values: [
        { label: "1 - Critical", value: "1 - Critical" },
        { label: "2 - High", value: "2 - High" },
        { label: "3 - Moderate", value: "3 - Moderate" },
        { label: "4 - Low", value: "4 - Low" },
      ],
      preset: {
        matchMode: "in",
        value: ["1 - Critical", "2 - High", "3 - Moderate", "4 - Low"],
      },
    },
    showSortIcon: true,
    default: true,
    serviceNowField: IncidentTableColumnNames.priority,
    exportField: exportField.priority,
  },
  {
    field: "state",
    header: "State",
    display: {
      type: "chip",
      colors: {
        New: "new",
        "In Progress": "inprogress",
        "Awaiting Info": "awaitinginfo",
        Resolved: "resolved",
        Closed: "closed",
      },
    },
    filter: {
      type: "multiSelect",
      values: [
        { label: "New", value: "New" },
        { label: "In Progress", value: "In Progress" },
        { label: "Awaiting Info", value: "Awaiting Info" },
        { label: "Resolved", value: "Resolved" },
        { label: "Closed", value: "Closed" },
      ],
      preset: {
        matchMode: "in",
        value: ["New", "In Progress", "Awaiting Info"],
      },
    },
    showSortIcon: true,
    default: true,
    serviceNowField: IncidentTableColumnNames.state,
    exportField: exportField.state,
  },
  {
    field: "holdReason",
    header: "On Hold Reason",
    display: { type: "text" },
    showSortIcon: true,
    default: false,
    serviceNowField: IncidentTableColumnNames.onHoldReason,
    exportField: exportField.onHoldReason,
    filter: {
      type: "multiSelect",
      values: [
        { label: "Awaiting Customer", value: "Awaiting Customer" },
        {
          label: "Awaiting Digital Space Vendor",
          value: "Awaiting Digital Space Vendor",
        },
        { label: "Awaiting Engineer Visit", value: "Awaiting Engineer Visit" },
        { label: "Awaiting Change", value: "Awaiting Change" },
        {
          label: "Awaiting Service Request",
          value: "Awaiting Service Request",
        },
        {
          label: "Awaiting Customer 3rd Party Supplier",
          value: "Awaiting Customer 3rd Party Supplier",
        },
        {
          label: "Awaiting Resolution Confirmation",
          value: "Awaiting Resolution Confirmation",
        },
        { label: "Awaiting Review Date", value: "Awaiting Review Date" },
        { label: "Awaiting Private Task", value: "Awaiting Private Task" },
      ],
    },
  },
  {
    field: "serviceComponent",
    header: "Service",
    display: { type: "text" },
    showSortIcon: true,
    default: false,
    serviceNowField: IncidentTableColumnNames.service,
    exportField: exportField.Service,
  },
  {
    field: "symptom",
    header: "Symptom",
    display: { type: "text" },
    showSortIcon: true,
    default: false,
    serviceNowField: IncidentTableColumnNames.symptom,
    exportField: exportField.symptom,
  },
  {
    field: "location",
    header: "Location",
    display: { type: "text" },
    showSortIcon: true,
    default: false,
    serviceNowField: IncidentTableColumnNames.location,
    exportField: exportField.Location,
  },
  {
    field: "cmdbCi",
    header: "Configuration Item",
    display: { type: "text" },
    showSortIcon: true,
    default: false,
    serviceNowField: IncidentTableColumnNames.cmdbCi,
    exportField: exportField.cmbdCi,
  },
];
