import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbService {
  private breadcrumbs = new BehaviorSubject<MenuItem[]>([]);
  breadcrumbs$ = this.breadcrumbs.asObservable();

  constructor() {}

  setBreadcrumbs(breadcrumbs: MenuItem[]): void {
    if (
      breadcrumbs?.length &&
      !breadcrumbs.find((breadcrumb) => breadcrumb?.icon?.includes("home"))
    ) {
      breadcrumbs.unshift({
        icon: "pi pi-home",
        routerLink: "/secure/dashboard",
      });
    }
    if (breadcrumbs?.length) {
      const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
      if (!lastBreadcrumb?.hasOwnProperty("command")) {
        if (lastBreadcrumb?.hasOwnProperty("routerLink")) {
          delete lastBreadcrumb.routerLink;
        }
        lastBreadcrumb.command = () => window.location.reload();
      }
    }
    breadcrumbs = breadcrumbs.map((breadcrumb) => {
      if (!breadcrumb?.styleClass) {
        breadcrumb.styleClass = "cursor-pointer";
      }
      return breadcrumb;
    });
    this.breadcrumbs.next(breadcrumbs);
  }

  clearBreadcrumbs() {
    this.breadcrumbs.next([]);
  }
}
