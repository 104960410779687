import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { MenuItem, Message } from "primeng/api";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { IUserProfile } from "../../../company/users/shared/user-models";
import { FileService } from "src/app/services/api/file/file.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CommentTypes } from "src/app/components/misc/comments/comments.component";
import { FileManagerComponent } from "src/app/components/misc/pop-up/file-manager/file-manager.component";
import { FileManagerDialogData } from "src/app/components/misc/pop-up/file-manager/file-manager.models";
import { FileManagerService } from "src/app/components/misc/pop-up/file-manager/file-manager.service";
import { ViewFilesComponent } from "src/app/components/misc/pop-up/view-files/view-files.component";
import { CreateCommentRequest } from "src/app/models/comments/comment.models";
import { TableNames } from "src/app/models/servicenow.model";
import { OrderLineItemService } from "src/app/services/api/order-line-item/order-line-item.service";
import { PermissionService } from "src/app/services/permissions.service";
import { OrderLineItem, ordersColors } from "src/app/models/order/order.model";
import {
  GenericApiErrorMessage,
  IsDisplayableException,
} from "src/app/helpers/error.helper";
import { ToastService, ToastType } from "src/app/services/global/toast.service";
import { GetTicketItemDisplayContainerClasses } from "src/app/helpers/tickets.helper";

@Component({
  selector: "app-order-line-item",
  templateUrl: "./order-line-item.component.html",
  styleUrls: ["./order-line-item.component.scss"],
})
export class OrderLineItemComponent implements OnInit {
  CommentTypes = CommentTypes;
  commentSubject = new BehaviorSubject(null);
  comments$ = this.commentSubject.asObservable();
  comments: any;
  user: IUserProfile;
  loading: boolean = false;
  orderLineItemId: string;
  orderLineItem: OrderLineItem;
  address: string;
  orderSysId: string;
  canUpdateOrderLine: boolean = false;
  isImpersonating: boolean = false;
  ref: DynamicDialogRef;
  attachmentFiles: any;
  orderNumber: string;
  colors = ordersColors;
  addressReference: string;
  canViewOrderInformation: boolean = false;

  messages: Message[] | undefined = [];

  getTicketItemDisplayContainerClasses = GetTicketItemDisplayContainerClasses;

  get canViewOrderInfo(): boolean {
    return (
      !this.loading &&
      this.canViewOrderInformation &&
      !!this.orderNumber &&
      !!this.addressReference
    );
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private orderLineItemService: OrderLineItemService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private fileService: FileService,
    private dialogService: DialogService,
    private toastService: ToastService,
    public fileManagerService: FileManagerService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUserOrImpersonatedUser();
    this.canViewOrderInformation = this.permissionService.digitalSpaceID();
    this.isImpersonating = this.authService.isImpersonating();
    this.canUpdateOrderLine =
      this.user.uTimicoPortalPermissions.includes("TICKETS_ORDERS_UPDATE") ||
      this.user.uTimicoPortalPermissions.includes("TICKETS_COMPANY_UPDATE");
    this.activatedRoute.params.subscribe(async (params: Params) => {
      this.orderLineItemId = params["id"];
      await this.loadOrderLineItem();
      const breadcrumbs: MenuItem[] = [
        {
          label: "Order Requests",
          routerLink: "/secure/tickets/orders",
        },
        { label: this.orderLineItem?.number },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    });
  }

  async addComment(comment: string) {
    const commentRequest: CreateCommentRequest = {
      itemSysId: this.orderLineItem.sysId,
      comment: comment,
    };
    const addComment = await firstValueFrom(
      this.orderLineItemService.createComment(commentRequest)
    );

    if (addComment) {
      this.toastService.add({
        severity: ToastType.success,
        summary: "Adding Comment",
        detail: "Comment Added",
      });
      await this.loadOrderLineItem();
    }
  }

  async loadComments() {
    const comments = await firstValueFrom(
      this.orderLineItemService.getComments(this.orderLineItem.sysId)
    );
    const canAddComments =
      this.canUpdateOrderLine &&
      this.orderLineItem?.state !== "Fulfilled" &&
      this.orderLineItem?.state !== "Closed" &&
      this.orderLineItem?.state !== "Resolved" &&
      this.orderLineItem.state !== "Cancelled";

    this.commentSubject.next({
      comments: comments,
      canAddComments: canAddComments,
    });
  }

  async loadOrderLineItem() {
    this.loading = true;
    try {
      const orderLineItem: OrderLineItem = await firstValueFrom(
        this.orderLineItemService.getSingle(this.orderLineItemId)
      );
      this.orderSysId = orderLineItem.sysId;
      const addressArray = [];
      for (const key of Object.keys(orderLineItem.installationAddress)) {
        const value = orderLineItem.installationAddress[key];
        if (value) {
          addressArray.push(value);
        }
      }
      this.orderNumber = orderLineItem?.order;
      this.addressReference = orderLineItem?.addressReference;
      this.orderLineItem = orderLineItem;
      await this.loadComments();
      this.loadFiles();
      this.address = addressArray.join("\n");
    } catch (error) {
      console.error(error);
      let message = {
        severity: ToastType.error,
        summary: "Error",
        detail: GenericApiErrorMessage,
        closable: false,
      };
      if (IsDisplayableException(error?.status)) {
        message = {
          severity: ToastType.error,
          summary: "Error",
          detail: error.error,
          closable: false,
        };
      }
      this.messages = [message];
    } finally {
      this.loading = false;
    }
  }

  async loadFiles() {
    let files = await firstValueFrom(
      this.fileService.getFiles(this.orderSysId)
    );
    this.attachmentFiles = files.fileList;
  }

  async openFileManager() {
    const fileManagerData: FileManagerDialogData = {
      sysId: this.orderSysId,
      table: TableNames.ORDER_LINE,
    };

    this.ref = this.dialogService.open(FileManagerComponent, {
      showHeader: false,
      width: "45%",
      data: fileManagerData,
    });

    const result = await firstValueFrom(this.ref.onClose);
    if (result) {
      this.toastService.add({
        severity: ToastType.success,
        summary: "File Manager",
        detail: "File(s) Uploaded",
      });
      await this.loadOrderLineItem();
      return;
    }
    this.toastService.add({
      severity: ToastType.warning,
      summary: "Adding Comment",
      detail: "Operation Cancelled",
    });
  }

  openAttachments(): void {
    this.ref = this.dialogService.open(ViewFilesComponent, {
      showHeader: false,
      data: {
        files: this.attachmentFiles,
        sysId: this.orderSysId,
        table: TableNames.REQUESTED_ITEM,
      },
    });
  }

  getColor(value: string): string {
    switch (value) {
      case "New":
        return "new";
      case "In Progress":
        return "inprogress";
      case "Awaiting Info":
        return "awaitinginfo";
      case "Resolved":
        return "resolved";
      case "Closed":
        return "closed";
      case "Open":
        return "open";
      default:
        return "closed";
    }
  }
}
