import { TableColumnDisplay } from "../table";

export type ChangeColumn = {
  field: string;
  header: string;
  display: TableColumnDisplay;
  showSortIcon: boolean;
  default: boolean;
  disabled?: boolean;
  serviceNowField: string;
};

export type GetChangeRequestsResponseItem = {
  sysId: string;
  number: string;
  company: string;
  shortDescription: string;
  description: string;
  startDate: string;
  endDate: string;
  requestedBy: string;
  uRequestedFor: string;
  state: string;
  openedAt: string;
  uPortalLastAction: string;
  type: string;
  uImpactDetails: string;
  uPublic: boolean;
  uClosedStatus: string;
  uChangeReason: string;
  uTemplate: string;
  testPlan: string;
  active: boolean;
  openedBy: string;
  closeNotes: string;
  changePlan: string;
  backoutPlan: string;
  uImpactedUsers: string;
  uRiskDetails: string;
};

//Column names match export DTOs in Customer portal
export enum ChangeRequestTableColumnName {
  number = "number",
  state = "State",
  requestedFor = "URequestedFor.name",
  shortDescription = "shortDescription",
  startDate = "StartDate",
  endDate = "EndDate",
  closedStatus = "UClosedStatus",
}
