import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { cloneDeep } from "lodash-es";
import { DateTime } from "luxon";
import { MenuItem } from "primeng/api/menuitem";
import { Paginator } from "primeng/paginator";
import { Table } from "primeng/table";
import { firstValueFrom } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { BuildServiceNowQuery } from "src/app/helpers/servicenow/filter/servicenow-filter.helper";
import { SetSelectedColumns, TableReset } from "src/app/helpers/table.helper";
import { Filters, TableColumn, TableColumnColor } from "src/app/models/table";
import { CaseService } from "src/app/services/api/case/case.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { BreadcrumbService } from "src/app/services/general/breadcrumb/breadcrumb.service";
import { IUserProfile } from "../../company/users/shared/user-models";
import {
  ExportTable,
  GetExportItemsFromFullArray,
} from "../../products-and-services/science-logic/cmdb-devices/shared/export-devices";
import { DigitalSpaceTabMenuItem } from "src/app/components/misc/digital-space-tab-menu/digital-space-tab-menu.component";
import { ServiceNowFilterQuery } from "src/app/models/servicenow.model";
import { ConvertServiceNowDateToJsDate } from "src/app/helpers/date.helper";
import { sentenceCase } from "change-case";
import {
  serviceRequestClosedColumns,
  serviceRequestOpenColumns,
} from "./service-request.columns";
import {
  GetServiceRequestsResponse,
  ServiceRequestClosedStateValues,
  ServiceRequestOpenStateValues,
  ServiceRequestSizeLimit,
  ServiceRequestTableColumnName,
  ServiceRequestTabs,
} from "src/app/models/service-request/service-request.models";
import { ServiceRequestService } from "src/app/services/api/service-request/service-request.service";

@Component({
  selector: "service-requests",
  templateUrl: "service-requests.component.html",
  styleUrls: ["service-requests.component.scss"],
})
export class ServiceRequestsComponent implements OnInit {
  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("tablePaginator") paginator: Paginator;
  stateOptions: any[] = [
    { label: "Company", value: true },
    { label: "Mine", value: false },
  ];

  ServiceRequestTabs = ServiceRequestTabs;

  serviceRequestSubject = new BehaviorSubject<
    Array<GetServiceRequestsResponse>
  >([]);
  serviceRequest$ = this.serviceRequestSubject.asObservable();

  user: IUserProfile;

  hasCompanyToggle: boolean;
  companyToggle: boolean = true;

  _selectedColumns: TableColumn[] = [];
  cols: TableColumn[] = cloneDeep(serviceRequestOpenColumns);

  defaultRowsShown = 25;

  firstLoad: boolean = true;

  first: number = 0;

  exportOptions = [
    {
      label: "All",
      value: "all",
      command: () => this.exportAll(),
    },
    {
      label: "In View",
      value: "in_view",
      command: () => this.defaultExport(),
    },
  ];

  tabs: DigitalSpaceTabMenuItem[] = [
    {
      label: ServiceRequestTabs.open,
      hovered: false,
    },
    {
      label: ServiceRequestTabs.closed,
      hovered: false,
    },
  ];

  activeTab: DigitalSpaceTabMenuItem = this.tabs[0];

  get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serviceRequestService: ServiceRequestService,
    private caseService: CaseService,
    private authService: AuthService,
    private breadcrumbService: BreadcrumbService
  ) {}

  async ngOnInit() {
    this.user = await this.authService.getUserOrImpersonatedUser();
    const breadcrumbs: MenuItem[] = [
      {
        label: "Service Requests",
        routerLink: "/secure/tickets/service-requests",
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
    this.hasCompanyToggle = this.user.uTimicoPortalPermissions.includes(
      "TICKETS_COMPANY_READ"
    );
    await this.loadRequests();
  }

  loadTableFromURL(): Filters {
    const filters: Filters = {};
    const params = this.route.snapshot.params;
    if (params["OpenedAt.StartDate"] && params["OpenedAt.EndDate"]) {
      const startDateDateTime = DateTime.fromFormat(
        params["OpenedAt.StartDate"],
        "dd/MM/yyyy"
      );
      // Convert to a JavaScript Date object
      const startDate = startDateDateTime.toJSDate();
      const endDateDateTime = DateTime.fromFormat(
        params["OpenedAt.EndDate"],
        "dd/MM/yyyy"
      );
      // Convert to a JavaScript Date object
      const endDate = endDateDateTime.toJSDate();
      const values = [startDate, endDate];
      let column = this.cols.find(
        (currentFilter) =>
          currentFilter.serviceNowField ===
          ServiceRequestTableColumnName.openedAt
      );
      if (column) {
        filters[column.field] = {
          value: values,
          matchMode: "between",
        };
      }
    }
    return filters;
  }

  tabChange(tab: DigitalSpaceTabMenuItem) {
    this.activeTab = tab;
    this.firstLoad = true;
    if (tab.label === ServiceRequestTabs.open) {
      this.cols = cloneDeep(serviceRequestOpenColumns);
    }
    if (tab.label === ServiceRequestTabs.closed) {
      this.cols = cloneDeep(serviceRequestClosedColumns);
    }
    this.loadRequests();
  }

  async loadRequests() {
    this.dataTable.loading = true;

    this.serviceRequestSubject.next([]);

    if (this.firstLoad) {
      const additionalFilters = this.loadTableFromURL();
      this.reset(this.dataTable, false, this.cols, additionalFilters);
      this.firstLoad = false;
    }

    const requests = (
      await Promise.all([this.getServiceRequests(), this.getCases()])
    ).flatMap((results) => results);
    this.serviceRequestSubject.next(requests);

    this.dataTable.loading = false;
  }

  async getServiceRequests() {
    const serviceRequestCount =
      (await firstValueFrom(
        this.serviceRequestService.getCount(
          this.getQueryFilter(this.dataTable, this.cols)
        )
      )) ?? 0;

    const serviceRequestRequests = [];
    const totalPages = Math.ceil(serviceRequestCount / ServiceRequestSizeLimit);
    for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
      serviceRequestRequests.push(
        firstValueFrom(
          this.serviceRequestService.getMultiple(
            this.getQueryFilter(
              this.dataTable,
              this.cols,
              ServiceRequestSizeLimit,
              pageNumber
            )
          )
        )
      );
    }

    const results = await Promise.all(serviceRequestRequests);

    return results
      .flatMap((result) => result)
      .map((item) => {
        return {
          ...item,
          type: "request",
          openedAt: ConvertServiceNowDateToJsDate(item.openedAt),
          state: item.state,
        };
      });
  }

  async getCases() {
    const count =
      (await firstValueFrom(
        this.caseService.getCount(
          this.getQueryFilter(this.dataTable, this.cols)
        )
      )) ?? 0;

    const requests = [];
    const totalPages = Math.ceil(count / ServiceRequestSizeLimit);
    for (let pageNumber = 0; pageNumber < totalPages; pageNumber++) {
      requests.push(
        firstValueFrom(
          this.caseService.getMultiple(
            this.getQueryFilter(
              this.dataTable,
              this.cols,
              ServiceRequestSizeLimit,
              pageNumber
            )
          )
        )
      );
    }

    const results = await Promise.all(requests);

    return results
      .flatMap((result) => result)
      .map((item) => {
        return {
          ...item,
          type: "case",
          openedAt: ConvertServiceNowDateToJsDate(item.openedAt),
          state: item.state,
        };
      });
  }

  getQueryFilter(
    table: Table,
    columns: TableColumn[],
    amount?: number,
    page?: number
  ): ServiceNowFilterQuery {
    let serviceNowFilterQuery = BuildServiceNowQuery(
      table,
      columns,
      amount,
      page
    );
    if (!this.companyToggle) {
      serviceNowFilterQuery.Filter.push({
        Column: ServiceRequestTableColumnName.requestedForSysId,
        Value: [this.user?.sysId],
      });
    }

    if (this.activeTab.label === ServiceRequestTabs.open) {
      serviceNowFilterQuery.Filter.push({
        Column: ServiceRequestTableColumnName.state,
        Value: ServiceRequestOpenStateValues,
      });
    }
    if (this.activeTab.label === ServiceRequestTabs.closed) {
      serviceNowFilterQuery.Filter.push({
        Column: ServiceRequestTableColumnName.state,
        Value: ServiceRequestClosedStateValues,
      });
    }
    return serviceNowFilterQuery;
  }

  async onFiltersChanged() {
    if (this.activeTab.label === ServiceRequestTabs.closed) {
      await this.loadRequests();
    }
  }

  async onToggleChange(): Promise<void> {
    await this.loadRequests();
  }

  getColor(colors: TableColumnColor[], value: string) {
    return colors[value] ?? null;
  }

  handleColumnChange(val: any[]) {
    this.cols = this.cols.filter((col) =>
      val.map((valCol) => valCol.header).includes(col.header)
    );
  }

  handleRowClicked(event) {
    if (event.type === "request") {
      this.router.navigateByUrl(
        `secure/tickets/service-request/${event.sysId}`
      );
    } else if (event.type === "case") {
      this.router.navigateByUrl(`secure/tickets/case/${event.sysId}`);
    }
  }

  defaultExport() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      GetExportItemsFromFullArray(
        displayedRows.map((item) => {
          return {
            ...item,
            openedAt: DateTime.fromJSDate(item.openedAt).toFormat(
              "dd/MM/yyyy HH:ss"
            ),
          };
        }),
        this.dataTable.first ?? 0,
        this.dataTable._rows ?? this.defaultRowsShown
      ),
      this.selectedColumns,
      "all"
    );
  }

  async exportAll() {
    const displayedRows = this.dataTable.filteredValue || this.dataTable.value;
    ExportTable(
      this.dataTable,
      displayedRows.map((item) => {
        return {
          ...item,
          openedAt: DateTime.fromJSDate(item.openedAt).toFormat(
            "dd/MM/yyyy HH:ss"
          ),
        };
      }),
      this.selectedColumns,
      "all"
    );
  }

  async resetTable() {
    this.cols =
      this.activeTab.label === ServiceRequestTabs.closed
        ? cloneDeep(serviceRequestClosedColumns)
        : cloneDeep(serviceRequestOpenColumns);
    this.firstLoad = true;
    await this.loadRequests();
  }

  reset(
    table: Table,
    firstLoad: boolean = false,
    columns: TableColumn[],
    additionalFilters?: Filters
  ) {
    this._selectedColumns = SetSelectedColumns(
      this.cols,
      columns.filter((column) => column?.default).map((column) => column?.field)
    );
    TableReset(table, columns, {
      firstLoad,
      paginator: this.paginator,
      additionalFilters: additionalFilters,
    });
  }
}
